exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-posts-art-of-war-js": () => import("./../../../src/pages/blog/posts/art-of-war.js" /* webpackChunkName: "component---src-pages-blog-posts-art-of-war-js" */),
  "component---src-pages-blog-posts-can-you-get-0-elo-js": () => import("./../../../src/pages/blog/posts/can-you-get-0-elo.js" /* webpackChunkName: "component---src-pages-blog-posts-can-you-get-0-elo-js" */),
  "component---src-pages-blog-posts-chess-beginner-tips-js": () => import("./../../../src/pages/blog/posts/chess-beginner-tips.js" /* webpackChunkName: "component---src-pages-blog-posts-chess-beginner-tips-js" */),
  "component---src-pages-blog-posts-chess-benefits-js": () => import("./../../../src/pages/blog/posts/chess-benefits.js" /* webpackChunkName: "component---src-pages-blog-posts-chess-benefits-js" */),
  "component---src-pages-blog-posts-chess-history-js": () => import("./../../../src/pages/blog/posts/chess-history.js" /* webpackChunkName: "component---src-pages-blog-posts-chess-history-js" */),
  "component---src-pages-blog-posts-cultural-significance-js": () => import("./../../../src/pages/blog/posts/cultural-significance.js" /* webpackChunkName: "component---src-pages-blog-posts-cultural-significance-js" */),
  "component---src-pages-blog-posts-learn-the-sicilian-js": () => import("./../../../src/pages/blog/posts/learn-the-sicilian.js" /* webpackChunkName: "component---src-pages-blog-posts-learn-the-sicilian-js" */),
  "component---src-pages-blog-posts-players-js": () => import("./../../../src/pages/blog/posts/players.js" /* webpackChunkName: "component---src-pages-blog-posts-players-js" */),
  "component---src-pages-blog-posts-post-game-analysis-js": () => import("./../../../src/pages/blog/posts/post-game-analysis.js" /* webpackChunkName: "component---src-pages-blog-posts-post-game-analysis-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-pages-home-home-js": () => import("./../../../src/pages/landingPages/Home/Home.js" /* webpackChunkName: "component---src-pages-landing-pages-home-home-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

